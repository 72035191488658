import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
import { MDBSideNav, MDBSideNavNav, MDBSideNavLink, MDBIcon } from "mdbreact";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import "./UserCreate.css";
import DashboardV2 from "./v2";
import config from "../../config";

// const GenderButtons = () => {
//   const [selectedGender, setSelectedGender] = useState;(null)
// };

const Update = ({ onUserAdded }) => {
  const [selectGender, setSelectGender] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const genderOptions = [
    { text: "Male", value: "male" },
    { text: "Female", value: "female" },
  ];

  const [error, setError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [data, setData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formValues, setFormValues] = useState(
    location.state === null || location.state === undefined
      ? {
          firstname: "",
          lastname: "",
          email: "",
          phonenumber: "",
          gender: "",
          city: "",
          state: "",
          region: "",
          role: "",
          password: "1234",
        }
      : {
          firstname: location.state.userToUpdate.FIRST_NAME,
          lastname: location.state.userToUpdate.LAST_NAME,
          email: location.state.userToUpdate.EMAIL,
          phonenumber: location.state.userToUpdate.MOBILE,
          gender: location.state.userToUpdate.GENDER,
          city: location.state.userToUpdate.CITY,
          state: location.state.userToUpdate.STATE,
          region: location.state.userToUpdate.REGION,
          role: location.state.userToUpdate.ROLE,
        }
  );

  const onChangeA = (e, ctrlName, ctrlValue) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [ctrlName]: ctrlValue,
    }));
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleMobileChange = (e) => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(e.target.value)) {
      setMobileError("* Enter Valid Mobile No");
    } else {
      setMobileError("");
    }
    onChangeA(e, "phonenumber", e.target.value);
  };
  const handleEmailChange = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError("Invalid Email");
    } else {
      setError("");
    }
    onChangeA(e, "email", e.target.value);
  };

  const addUser = (e) => {
    e.preventDefault();

    if (
      formValues.firstname !== "" &&
      formValues.lastname !== "" &&
      formValues.email !== "" &&
      formValues.phonenumber !== "" &&
      formValues.gender !== "" &&
      formValues.city !== "" &&
      formValues.state !== "" &&
      formValues.region !== "" &&
      formValues.role !== ""
    ) {
      const payload = {
        FIRST_NAME: formValues.firstname,
        LAST_NAME: formValues.lastname,
        MOBILE: formValues.phonenumber,
        EMAIL: formValues.email,
        GENDER: formValues.gender,
        CITY: formValues.city,
        STATE: formValues.state,
        REGION: formValues.region,
        PASSWORD: formValues.password,
        ROLE: formValues.role,
      };

      // console.log("Form Values:", formValues);
      // console.log("Payload:", payload);
      // console.log("update:", location.state.userToUpdate);

      Axios.post(`${config.baseUrl}/addUser`, payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          console.log("Response:", response);

          if (response?.status === 200) {
            setData([...data, payload]);
            setShowSuccessPopup(true);
            toast.success("User Added Successfully");

            // Reset the formValues after successful addition
            setFormValues({
              firstname: "",
              lastname: "",
              email: "",
              phonenumber: "",
              gender: "",
              city: "",
              state: "",
              region: "",
              password: "",
              role: "",
            });

            // If needed, navigate to the userlist page
            // history.push("/userlist");
          } else {
            console.error("Error adding user:", response?.status);
            toast.error("Error adding user. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error adding user:", error);
          toast.error("Error adding user. Please try again.");
        });
    } else {
      toast.error("Please enter all the fields.", {
        position: "top-right",
      });
    }
  };

  const updateUser = (e) => {
    e.preventDefault();

    if (
      formValues.firstname !== "" &&
      formValues.lastname !== "" &&
      formValues.email !== "" &&
      formValues.phonenumber !== "" &&
      formValues.gender !== "" &&
      formValues.city !== "" &&
      formValues.state !== "" &&
      formValues.region !== "" &&
      formValues.role !== ""
    ) {
      const payload = {
        FIRST_NAME: formValues.firstname,
        LAST_NAME: formValues.lastname,
        MOBILE: formValues.phonenumber,
        EMAIL: formValues.email,
        GENDER: formValues.gender,
        CITY: formValues.city,
        STATE: formValues.state,
        REGION: formValues.region,
        PASSWORD: formValues.password,
        ROLE: formValues.role,
        USER_ID: location.state.userToUpdate.USER_ID,
      };
      console.log(payload);
      Axios.post(`${config.baseUrl}/updateUser`, payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          console.log("Response:", response);

          if (response?.status === 200) {
            setData([...data, payload]);
            setShowSuccessPopup(true);
            toast.success("User Updated Successfully");

            // Reset the formValues after successful addition
            setFormValues({
              firstname: "",
              lastname: "",
              email: "",
              phonenumber: "",
              gender: "",
              city: "",
              state: "",
              region: "",
              password: "",
              role: "",
            });

            // If needed, navigate to the userlist page
            // history.push("/userlist");
          } else {
            console.error("Error updating user:", response?.status);
            toast.error("Error updating user. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          toast.error("Error updating user. Please try again.");
        });
    } else {
      toast.error("Please enter all the fields.", {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <style>
        {`
        body, html {
          margin: 0;
          padding: 0;
          min-height: 100%;
          background-color: #FFFF;
        }
      `}
      </style>{" "}
      {console.log(location.state?.userToUpdate)}
      <MDBCard
        style={{
          background: "#F3F3F3",
          marginTop: "25px",
          marginLeft: "3rem",
          width: "62rem",
          height: "33rem",
          borderRadius: "12px",
          position: "absolute",
          top: "50%",
          boxShadow: "none",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <MDBCardHeader
          style={{
            color: "white",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomLeftRadius: "0", // Set to 0 or undefined for no radius on the bottom-left corner
            borderBottomRightRadius: "0",
            // border: "none",
            height: "12%",
            fontSize: "22px",
            fontWeight: "bold",
          }}
          id="UserCreation"
        >
          <p style={{ color: "#FF725E", marginTop: "20px" }}>User Update</p>
        </MDBCardHeader>
        <MDBCardBody>
          <form>
            <div
              style={{
                marginLeft: "6%",
                width: "60rem",
                marginLeft: "4.8rem",
                height: "22rem",
                justifyContent: "center",
                marginTop: "2px",
              }}
            >
              <input
                style={{
                  width: "25%",
                  height: "12%",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  border: "none",
                  marginLeft: "1.5%",
                  justifyContent: "center",
                  outline: "none",
                  paddingLeft: "10px",
                }}
                type="text"
                name="firstname"
                value={formValues.firstname}
                placeholder={formValues.firstname == "" ? " First Name" : null}
                // label="first name"
                onChange={(e) => onChangeA(e, "firstname", e.target.value)}
              />
              <label className="input-label">First Name</label>

              <label className="input-label-last">Last Name</label>
              <input
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginTop: "20px",
                  marginLeft: "2%",
                  border: "none",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingLeft: "10px",
                }}
                type="text"
                name="lastname"
                value={formValues.lastname}
                placeholder={formValues.lastname == "" ? "Last Name" : null}
                onChange={(e) => onChangeA(e, "lastname", e.target.value)}
              />
              <label className="input-label-last">Last Name</label>

              <label className="input-label-email">Email</label>
              <input
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "2%",
                  border: "none",
                  paddingLeft: "10px",
                }}
                type="text"
                name="email"
                value={formValues.email}
                placeholder={formValues.email == "" ? " Email" : null}
                onChange={handleEmailChange}
              />
              {error ? (
                <p
                  style={{
                    color: "red",
                    marginLeft: "54%",
                    position: "absolute",
                  }}
                >
                  {error}
                </p>
              ) : (
                ""
              )}
              <label className="input-label-email">Email</label>

              <label className="input-label-phone">Mobile No</label>
              <input
                style={{
                  width: "25%",
                  height: "12%",
                  borderRadius: "5px",
                  outline: "none",
                  marginTop: "8%",
                  marginLeft: "1.5%",
                  border: "none",
                  paddingLeft: "10px",
                }}
                type="text"
                name="mobile"
                value={formValues.phonenumber}
                placeholder={formValues.mobile == "" ? " Phone Number" : null}
                onChange={handleMobileChange}
              />
              {mobileError ? (
                <p
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "18px",
                  }}
                >
                  {mobileError}
                </p>
              ) : (
                ""
              )}
              <label className="input-label-phone">Mobile No</label>

              <label className="input-label-gender">Gender</label>
              <select
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "2%",
                  border: "none",
                  color: "gray",
                  fontSize: "small",
                  fontWeight: "bold",
                }}
                id="gender"
                value={formValues.gender}
                onChange={(e) => onChangeA(e, "gender", e.target.value)}
              >
                <option value="">Choose Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="others">Others</option>
              </select>
              <label className="input-label-gender">Gender</label>

              <label className="input-label-city">City</label>
              <select
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "2%",
                  border: "none",
                  color: "gray",
                  fontSize: "small",
                  fontWeight: "bold",
                }}
                name="city"
                value={formValues.city}
                onChange={(e) => onChangeA(e, "city", e.target.value)}
              >
                <option value="">Select City</option>
                <option value="Vishakapatnam">Vishakapatnam</option>
                <option value="Sonipat">Sonipat</option>
                {/* Add more city options as needed */}
              </select>
              <label className="input-label-city">City</label>

              <label className="input-label-state">State</label>
              <input
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "1.5%",
                  border: "none",
                  marginTop: "8%",
                  paddingLeft: "10px",
                }}
                type="text"
                name="state"
                value={formValues.state}
                placeholder={formValues.state == "" ? " State " : null}
                onChange={(e) => onChangeA(e, "state", e.target.value)}
              />
              <label className="input-label-state">State</label>

              <label className="input-label-region">Region</label>
              <select
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "2%",
                  border: "none",
                  color: "gray",
                  fontSize: "small",
                  fontWeight: "bold",
                }}
                value={formValues.region}
                name="region"
                onChange={(e) => onChangeA(e, "region", e.target.value)}
              >
                <option value="">Select Region</option>
                <option value="Urban">Urban</option>
                <option value="Rural">Rural</option>
              </select>
              <label className="input-label-region">Region</label>

              <label className="input-label-role">Role</label>
              <select
                style={{
                  width: "25%",
                  height: "12%",
                  outline: "none",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  marginLeft: "2%",
                  border: "none",
                  color: "gray",
                  fontSize: "small",
                  fontWeight: "bold",
                }}
                name="role"
                value={formValues.role}
                onChange={(e) => onChangeA(e, "role", e.target.value)}
              >
                <option value="">Select Role</option>
                <option value="user">Mobile App User</option>
                <option value="admin">Web User</option>
              </select>
              <label className="input-label-role">Role</label>
            </div>

            {location.state ? (
              <button
                style={{
                  marginLeft: "26rem",
                  marginTop: "1px",
                  width: "6.5rem",
                  height: "3rem",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#FF724E", // Set your desired color code here
                  color: "white",
                  display: "inline-block", // Display the button in one line
                }}
                onClick={updateUser}
              >
                Update User
              </button>
            ) : (
              <button
                style={{
                  marginLeft: "26rem",
                  marginTop: "1px",
                  width: "6.5rem",
                  height: "3rem",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#FF724E", // Set your desired color code here
                  color: "white",
                  display: "inline-block", // Display the button in one line
                }}
                onClick={addUser}
              >
                Add User
              </button>
            )}

            {/* {showSuccessPopup && (
              <div
                style={{
                  background: "green",
                  color: "white",
                  padding: "10px",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                User updated successfully!
              </div>
            )} */}
          </form>
          {/* </div>  */}

          <ToastContainer />
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default Update;
